<template>
    <div class="article">
        <article v-if="blog && blog.id">
            <section>
                <div class="container mt-5">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="article position-relative">
                                <div class="date">
                                    {{ blog.publication_date }}
                                </div>
                                <h1 class="font-normal mt-4 mb-3">
                                    {{ blog.title }}
                                </h1>
                                <p class="lead" v-html="blog.lead"></p>

                                <!--<small
                                    >{{ $t("Szerző") }}:
                                    {{ blog.author_name }}</small
                                >-->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="mb-3" style="--aspect-ratio:2/1;">
                                <img :src="blog.main_image" />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="social mb-4">
                                <div class="circle color-blue">
                                    <a :href="shareFb" target="_blank"
                                        ><img src="/img/icon-fb.svg" />
                                    </a>
                                </div>
                                <div class="circle color-green">
                                    <a :href="shareTw" target="_blank"
                                        ><img src="/img/icon-twitter.svg" />
                                    </a>
                                </div>
                                <div class="circle color-yellow">
                                    <a :href="sharePin" target="_blank"
                                        ><img src="/img/icon-pinterest.svg" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" v-html="blog.content"></div>
                    </div>
                </div>
            </section>
        </article>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-xl-3">
                        <p>{{ $t("További cikkek") }}</p>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="col-6 col-md-4 col-xl-3"
                        v-for="(item, index) in others"
                        v-bind:key="'others-' + index"
                    >
                        <div class="position-relative">
                            <div
                                class="mb-3"
                                style="--aspect-ratio:1/1;cursor:pointer;"
                            >
                                <img :src="item.main_image" />
                            </div>
                            <p>
                                {{ item.title }}
                            </p>
                            <router-link
                                :to="{
                                    name: 'Article',
                                    params: { slug: item.slug }
                                }"
                                class="stretched-link"
                            ></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Article",
    data() {
        return {
            blog: {},
            others: []
        };
    },
    beforeMount() {
        this.getBlog();
    },
    watch: {
        $route: {
            handler: function() {
                this.getBlog();
            },
            deep: true
        }
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        },
        shareFb: function() {
            return (
                "http://www.facebook.com/share.php?u=" + document.location.href
            );
        },
        shareTw: function() {
            return (
                "http://twitter.com/share?text=Névmatrica&url=" +
                document.location.href +
                "&hashtags=sticker"
            );
        },
        sharePin: function() {
            return (
                "https://hu.pinterest.com/pin/create/link/?url=" +
                document.location.href
            );
        }
    },
    methods: {
        getBlog() {
            this.$http
                .get(`${this.url}/blog/get/${this.$route.params.slug}`)
                .then(response => {
                    this.blog = response.data.blog;
                    this.others = response.data.others;
                });
        }
    }
};
</script>

<style scoped></style>
