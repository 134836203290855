<template>
    <div class="blog">
        <section class="first-section">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 offset-xl-3 text-center">
                        <h1 class="mt-100">{{ $t("Blog") }}</h1>
                    </div>
                </div>
                <div class="row">
                    <div
                        class="col-md-4 col-xl-3"
                        v-for="(item, index) in blog.data"
                        v-bind:key="'blog-' + index"
                    >
                        <div class="article position-relative">
                            <div
                                class="mb-3"
                                style="--aspect-ratio:1/1;cursor:pointer;"
                            >
                                <img :src="item.main_image" :alt="item.title" />
                            </div>
                            <div class="date">{{ item.publication_date }}</div>
                            <div class="h4 my-3">
                                {{ item.title }}
                            </div>
                            <div class="small-lead" v-html="item.lead"></div>
                            <router-link
                                :to="{
                                    name: 'Article',
                                    params: { slug: item.slug }
                                }"
                                class="stretched-link"
                            ></router-link>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li class="page-item">
                                    <router-link
                                        class="page-link"
                                        href="#"
                                        aria-label="Previous"
                                        :to="{
                                            name: 'Blog',
                                            query: { page: 1 }
                                        }"
                                        v-bind:class="{
                                            disabled:
                                                blog.meta.current_page === 1
                                        }"
                                    >
                                        <img
                                            src="/img/arrow_prev.svg"
                                            class="page-icon"
                                        />
                                    </router-link>
                                </li>
                                <li
                                    class="page-item active"
                                    v-if="blog.meta.current_page > 1"
                                >
                                    <router-link
                                        class="page-link"
                                        :to="{
                                            name: 'Blog',
                                            query: {
                                                page: blog.meta.current_page - 1
                                            }
                                        }"
                                        >{{
                                            blog.meta.current_page - 1
                                        }}</router-link
                                    >
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">{{
                                        blog.meta.current_page
                                    }}</a>
                                </li>
                                <li
                                    class="page-item"
                                    v-if="
                                        blog.meta.total > blog.meta.current_page
                                    "
                                >
                                    <router-link
                                        class="page-link"
                                        :to="{
                                            name: 'Blog',
                                            query: {
                                                page: blog.meta.current_page + 1
                                            }
                                        }"
                                        >{{
                                            blog.meta.current_page + 1
                                        }}</router-link
                                    >
                                </li>
                                <li class="page-item">
                                    <router-link
                                        class="page-link"
                                        href="#"
                                        aria-label="Next"
                                        :to="{
                                            name: 'Blog',
                                            query: { page: blog.meta.total }
                                        }"
                                        v-bind:class="{
                                            disabled:
                                                blog.meta.current_page ===
                                                blog.meta.total
                                        }"
                                    >
                                        <img
                                            src="/img/arrow_next.svg"
                                            class="page-icon"
                                        />
                                    </router-link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Blog",
    data() {
        return {
            blog: {}
        };
    },
    beforeMount() {
        this.getBlog();
    },
    watch: {
        $route: {
            handler: function() {
                this.getBlog();
            },
            deep: true
        }
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        }
    },
    methods: {
        getBlog() {
            let page = 1;
            if (this.$route.query && this.$route.query.page) {
                page = this.$route.query.page;
            }

            this.$http
                .get(`${this.url}/blog/get-list?page=${page}`)
                .then(response => {
                    this.blog = response.data;
                });
        }
    }
};
</script>

<style scoped></style>
