var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blog"},[_c('section',{staticClass:"first-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6 offset-xl-3 text-center"},[_c('h1',{staticClass:"mt-100"},[_vm._v(_vm._s(_vm.$t("Blog")))])])]),_c('div',{staticClass:"row"},_vm._l((_vm.blog.data),function(item,index){return _c('div',{key:'blog-' + index,staticClass:"col-md-4 col-xl-3"},[_c('div',{staticClass:"article position-relative"},[_c('div',{staticClass:"mb-3",staticStyle:{"--aspect-ratio":"1/1","cursor":"pointer"}},[_c('img',{attrs:{"src":item.main_image,"alt":item.title}})]),_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.publication_date))]),_c('div',{staticClass:"h4 my-3"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"small-lead",domProps:{"innerHTML":_vm._s(item.lead)}}),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{
                                name: 'Article',
                                params: { slug: item.slug }
                            }}})],1)])}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('nav',{attrs:{"aria-label":"Page navigation example"}},[_c('ul',{staticClass:"pagination justify-content-center"},[_c('li',{staticClass:"page-item"},[_c('router-link',{staticClass:"page-link",class:{
                                        disabled:
                                            _vm.blog.meta.current_page === 1
                                    },attrs:{"href":"#","aria-label":"Previous","to":{
                                        name: 'Blog',
                                        query: { page: 1 }
                                    }}},[_c('img',{staticClass:"page-icon",attrs:{"src":"/img/arrow_prev.svg"}})])],1),(_vm.blog.meta.current_page > 1)?_c('li',{staticClass:"page-item active"},[_c('router-link',{staticClass:"page-link",attrs:{"to":{
                                        name: 'Blog',
                                        query: {
                                            page: _vm.blog.meta.current_page - 1
                                        }
                                    }}},[_vm._v(_vm._s(_vm.blog.meta.current_page - 1))])],1):_vm._e(),_c('li',{staticClass:"page-item active"},[_c('a',{staticClass:"page-link",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.blog.meta.current_page))])]),(
                                    _vm.blog.meta.total > _vm.blog.meta.current_page
                                )?_c('li',{staticClass:"page-item"},[_c('router-link',{staticClass:"page-link",attrs:{"to":{
                                        name: 'Blog',
                                        query: {
                                            page: _vm.blog.meta.current_page + 1
                                        }
                                    }}},[_vm._v(_vm._s(_vm.blog.meta.current_page + 1))])],1):_vm._e(),_c('li',{staticClass:"page-item"},[_c('router-link',{staticClass:"page-link",class:{
                                        disabled:
                                            _vm.blog.meta.current_page ===
                                            _vm.blog.meta.total
                                    },attrs:{"href":"#","aria-label":"Next","to":{
                                        name: 'Blog',
                                        query: { page: _vm.blog.meta.total }
                                    }}},[_c('img',{staticClass:"page-icon",attrs:{"src":"/img/arrow_next.svg"}})])],1)])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }